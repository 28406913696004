define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
], (
  $, _, Backbone, WithKeyboardBehaviour,
) => Backbone.Marionette.ItemView.extend({

  behaviors() {
    const resetOnConfirmation = _.isBoolean(this.options.resetOnConfirmation) ? this.options.resetOnConfirmation : true;
    return {
      WithKeyboardBehaviour: {
        behaviorClass: WithKeyboardBehaviour,
        resetOnConfirmation,
      },
    };
  },

  ui: {
    input: 'input',
  },

  template() {
    return '<input type="text" readonly="readonly" ignore-keyboard="true" class="form-control text-right themed-background-light" placeholder="">';
  },

  initialize(options) {
    this.autoSelect = options.autoSelect || false;
    this.inputDisabled = options.inputDisabled || false;
    this.keyboardMode = this.keyboardModel.MODE_INTEGER;
    this.firstInput = true;

    this.attributeName = 'value';
    if ('attributeName' in this.options) {
      this.attributeName = this.options.attributeName;
    }

    if (!('model' in this.options)) {
      const data = {};
      data[this.attributeName] = '';
      this.model = new Backbone.Model(data);
    }

    if ('startValue' in this.options) {
      this.model.set(this.attributeName, this.options.startValue);
    }

    this.currentValue = this.model.get(this.attributeName);

    const self = this;

    this.model.on(`change:${this.attributeName}`, (model, value) => {
      self.currentValue = value;
      if (!self.isDestroyed) {
        self.render();
      }
    });
  },

  getValue() {
    return this.model.get(this.attributeName);
  },

  setValue(value) {
    this.model.set(this.attributeName, value);
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberKey(key)) {
      let { currentValue } = this;

      if (this.firstInput) {
        currentValue = '';
        this.firstInput = false;
      }

      let newValue = currentValue + key;
      this.currentValue = newValue;
    }
  },

  onBackspacePress() {
    this.currentValue = this.removeLastCharacterFromString(this.currentValue);
  },

  onRender() {
    this.ui.input.val(this.currentValue);
    this.applyThemedBackground();
  },

  onConfirmation() {
    this.model.set(this.attributeName, this.currentValue);

    // Timeout needed to prevent unwanted triggering when putting the focus on another input after this one.
    setTimeout(() => {
      this.triggerMethod('view:confirm', this.currentValue);
    }, 10);
  },

  onCancel() {
    this.currentValue = this.model.get(this.attributeName);
    if (!this.isDestroyed) {
      this.render();
    }
  },

  onCancelPressed() {
    this.triggerMethod('view:cancel');
  },

  onModeSelected() {
    if (this.inputDisabled) this.keyboardModel.setNeutralMode();
  },

  setFocus() {
    if (!this.inputDisabled) {
      this.keyboardModel.setViewWithMode(this.cid, this.keyboardModel.MODE_INTEGER);
    }
  },

  unFocus() {
    this.keyboardModel.setNeutralMode();
  },

  handleAutoSelect() {
    if (this.autoSelect) {
      this.autoSelectInterval = setInterval(() => {
        // select this as mode when no mode is set
        if (!this.keyboardModel.hasMode()) {
          this.keyboardModel.setViewWithMode(this.cid, this.keyboardModel.MODE_INTEGER);
        }
      }, 200);
    }
  },

  handleDisabled() {
    if (this.inputDisabled) this.disableInput();
  },

  onShow() {
    this.handleAutoSelect();
    this.handleDisabled();
  },

  onDestroy() {
    if (this.autoSelectInterval) clearInterval(this.autoSelectInterval);
  },

  reset() {
    this.firstInput = true;
    this.currentValue = '';
    this.model.set(this.attributeName, '');
    this.render();
  },

  onModeDeselected() {},

  applyThemedBackground() {
    if (this.inputDisabled) {
      this.ui.input.removeClass('themed-background-light');
      this.ui.input.addClass('themed-background-muted');
    } else {
      this.ui.input.addClass('themed-background-light');
      this.ui.input.removeClass('themed-background-muted');
    }
  },

  disableInput() {
    this.inputDisabled = true;
    this.applyThemedBackground();
    if (this.keyboardModel.hasMode()) this.keyboardModel.setNeutralMode();
  },

  enableInput() {
    this.inputDisabled = false;
    this.applyThemedBackground();
  },
}));
