define([
  'jquery',
  'underscore',
  'backbone',
  'application',
  'modules/shop.cash-register-retail/templates/layouts/app/sidebarLeft.hbs',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/views/popups/cashDrawerPopup',
  'upx.modules/ShopModule/collections/ShopLedger',
  'modules/shop.cash-register-retail/models/settings/pointOfSale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/shop.cash-register-retail/views/layouts/app/connectionQuality',

  'modules/upx/collections/users',
  'modules/profile/models/profile',
  'modules/upx/components/upx',
  'modules/common/components/managers/configuration',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/currency',

  'modules/shop.cash-register-retail/components/backoffice',
  'modules/shop.common/components/commerceAuth',

  'upx.modules/ShopModule/models/ShopLedgerOperation',
  'modules/shop.cash-register-retail/acls/settings',

  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/models/layout',
  'modules/shop.common/components/mode',

  'modules/shop.cash-register-retail/events/app/fullScreenLoader',

  'modules/shop.cash-register-retail/models/settings/shopPos',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/components/feature',
  'modules/shop.cash-register-retail/components/onlineFoodOrder',
  'modules/shop.cash-register-retail/collections/upx/OnlineFoodOrder',
], (
  $, _, Backbone, App, Template, ReceiptPrinterModel, Locale,
  CashDrawerPopupView, ShopLedgerCollection, PointOfSaleModel, CashRegisterApi, MessagePopup, ConfirmPopup, ConnectionQualityView,
  UsersCollection, ProfileModel, Upx, ConfigurationManager, Toaster, Currency,
  Backoffice, CommerceAuth,
  ShopLedgerOperationModel, SettingsAcl,
  PrintingComponent, LayoutModel, ModeComponent,
  FullScreenLoaderEvent,
  ShopPosModel, DeviceConfigModel, FeatureComponent, OnlineFoodOrderComponent, OnlineFoodOrder,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className: 'sidebar-left',

  events: {
    'click [data-action="drawer"]': 'drawerClicked',
    'click [data-action="settings"]': 'settingsClicked',
    'click [data-action="return-products"]': 'returnProductsClicked',
    'click [data-action="repairs"]': 'repairsClicked',
    'click [data-action="checkout"]': 'checkoutClicked',
    'click [data-action="daily-reports"]': 'dailyReportsClicked',
    'click [data-action="online-food-order"]': 'onlineFoodOrderClicked',
    'click [data-action="customer"]': 'customerClicked',
    'click [data-action="catalog"]': 'catalogClicked',
    'click [data-action="open-orders"]': 'openOrdersClicked',
    'click [data-action="calendar"]': 'calendarClicked',
    'click [data-action="sticker-print"]': 'stickerPrintClicked',
    'click [data-action="backoffice"]': 'backofficeClicked',
    'click [data-action="hospitality"]': 'hospitalityClicked',
  },

  regions: {
    popup: '[data-region="popup"]',
    connectionQuality: '[data-region="connection-status"]',
  },

  defaultEventStuff(event) {
    event.stopPropagation(); // increases the touch speed
    event.preventDefault(); // increases the touch speed
    const $el = $(event.currentTarget);
    CashRegisterApi.logAction('SITEBAR_BUTTON_CLICKED', {
      button: $el.data('action'),
    });
    $el.blur();
  },

  calendarClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('calendar', { trigger: true });
  },

  hospitalityClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('hospitality', { trigger: true });
  },

  stickerPrintClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('sticker-print', { trigger: true });
  },

  backofficeClicked(e) {
    this.defaultEventStuff(e);

    CashRegisterApi.logAction('BACKOFFICE_BUTTON_CLICKED');

    const def = Backoffice.openBackoffice();

    new FullScreenLoaderEvent({
      deferred: def,
      title: Locale.translate('opening_backoffice'),
    }).trigger();

    def.fail((resp) => {
      if (resp.error !== 'timeout') {
        // timeout happens also when it was ok so we ignore it
        new MessagePopup().open(
          Locale.translate('could_not_open_the_backoffice_{0}', [resp.error]),
        );
      }
    });
  },

  customerClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('customers/search', { trigger: true });
  },

  catalogClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('products/catalog', { trigger: true });
  },

  openOrdersClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('open-orders/reload', { trigger: true });
  },

  settingsClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('pos-settings/reload', { trigger: true });
  },

  repairsClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('repairs', { trigger: true });
  },

  dailyReportsClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('daily-reports', { trigger: true });
  },

  onlineFoodOrderClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('online-food', { trigger: true });
  },

  checkoutClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('checkout', { trigger: true });
  },

  returnProductsClicked(e) {
    this.defaultEventStuff(e);
    Backbone.history.navigate('orders/reload', { trigger: true });
  },

  drawerClicked(e, direction, amount) {
    this.defaultEventStuff(e);
    const self = this;

    const def = new $.Deferred();

    if (!direction || !amount) {
      ReceiptPrinterModel.openDrawer();
    }

    if (PointOfSaleModel.get('id')) {
      const view = new CashDrawerPopupView();
      const viewDef = new $.Deferred();
      view.open(viewDef, direction, amount);

      viewDef.done((drawerResponse) => {
        const {
          direction, amount, note, print,
        } = drawerResponse;

        const confirmationDef = new $.Deferred();
        const confirmView = new ConfirmPopup();

        // Show a message popup when the given values are not correct
        // Check if the amount is set, its in a correct format and the value isn't 0.00
        if (amount && amount.match(/^\d+.\d\d/) && amount !== '0.00') {
          const region = self.getRegion('popup');
          region.show(confirmView);
          // SetTimeout is being called, because otherwise the view would disappear
          setTimeout(() => {
            if (direction === 'in') {
              confirmView.open(Locale.translate('are_you_sure_question'), Locale.translate('you_are_about_to_add_lessstronggreater{0}_eurlessstronggreater_to_the_cash_register_are_you_sure_question', amount), confirmationDef);
            } else if (direction === 'out') {
              confirmView.open(Locale.translate('are_you_sure_question'), Locale.translate('you_are_about_to_remove_lessstronggreater{0}_eurlessstronggreater_from_the_cash_register_are_you_sure_question', amount), confirmationDef);
            }
          });

          confirmationDef.then(() => {
            const shopLedgerCollection = new ShopLedgerCollection();
            const shopId = PointOfSaleModel.get('id');
            if (shopId) {
              $.when(
                shopLedgerCollection.fetch({
                  params: {
                    start: 0,
                    limit: 1,
                    filters: [{
                      name: 'shop_id__=',
                      val: PointOfSaleModel.get('id'),
                    }],
                  },
                }),
              ).then(() => {
                const ledger = shopLedgerCollection.first(); // there can be only one
                const shopLedgerOperationModel = new ShopLedgerOperationModel({
                  shop_ledger_id: ledger.get('id'),
                  balance_change: amount,
                  note,
                });
                if (ledger) {
                  const euro_amount = Currency.format('EUR', Currency.toCurrency(amount));
                  if (direction === 'in') {
                    $.when(shopLedgerOperationModel.newAdd()).then(() => {
                      Toaster.info(Locale.translate('{0}_added_to_the_cash_drawer', euro_amount));
                      if (print) {
                        PrintingComponent.printMutation(shopLedgerOperationModel, Locale.translate('in'));
                      }
                    }, () => {
                      Toaster.error(Locale.translate('failed_cash_drawer_mutation'));
                    });
                  } else {
                    $.when(shopLedgerOperationModel.newSubtract()).then(() => {
                      Toaster.info(Locale.translate('took_{0}_from_the_cash_drawer', euro_amount));
                      if (print) {
                        PrintingComponent.printMutation(shopLedgerOperationModel, Locale.translate('out'));
                      }
                    }, () => {
                      Toaster.error(Locale.translate('failed_cash_drawer_mutation'));
                    });
                  }
                } else {
                  def.reject({
                    error: Locale.translate('no_point_of_sale_is_set'),
                  });
                }
              }, def.reject);
            } else {
              def.reject({
                error: Locale.translate('no_point_of_sale_is_set'),
              });
            }
          }, () => {
            // SetTimeout is being called, because otherwise the view would disappear
            setTimeout(() => {
              self.drawerClicked(e, direction, amount);
            });
          });
        } else {
          Toaster.warning(Locale.translate('cancelled_cash_drawer_mutation'));
        }
      });
    } else {
      def.reject({
        error: Locale.translate('no_point_of_sale_is_set'),
      });
    }

    def.fail((response) => {
      const view = new MessagePopup();
      view.open(response.error);
    });
  },

  renderConnectionQuality() {
    const region = this.getRegion('connectionQuality');
    const view = new ConnectionQualityView();
    region.show(view);
  },

  onShow() {
    LayoutModel.on('layout:changed', this.render, this);
    OnlineFoodOrder.on('all', this.render, this);
  },

  onDestroy() {
    LayoutModel.off('layout:changed', this.render, this);
    OnlineFoodOrder.off('all', this.render, this);
  },

  onRender() {
    this.renderConnectionQuality();
  },

  serializeData() {
    const acl = new SettingsAcl();
    return {
      showRepairs: FeatureComponent.getFeatureActiveByAppName(
        FeatureComponent.APP_NAME_CORE_SHOP_MODULE_REPAIRS,
      ),
      showBackoffice: acl.authorized() && !ModeComponent.isInAppMode()
          && !ModeComponent.isInWebMode(),
      showSettings: acl.authorized(),
      showCalendar: LayoutModel.showCalendarInMenu(),
      showStickerPrint: LayoutModel.showStickerPrintInMenu() && !ModeComponent.isInAppMode(),
      showDailyReports: !CommerceAuth.hasOrderOnlyAccess(),
      showOnlineFood: OnlineFoodOrderComponent.isOnlineOrderEnabled(),
      onlineFoodOrderCount: OnlineFoodOrderComponent.getNewOrderCount(),
      showHospitality: !ShopPosModel.get('hospitality_tabs') && ShopPosModel.get('mode') === DeviceConfigModel.MODE_Hospitality,
    };
  },
}));
