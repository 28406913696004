define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/layout',
  'modules/shop.cash-register-retail/templates/layouts/fullscreen/layout',
], (
  $, _, Backbone, Layout, Template,
) => Layout.extend({
  template: Template,

  name: 'modules/shop.cash-register-retail/views/layouts/fullscreen/layout',

  regions: {
    page: '[data-region="page-content"]',
  },

}));
