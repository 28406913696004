define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'toastr',

  'modules/shop.cash-register-retail/collections/employee',

  'modules/admin/components/select2',
], (
  $, _, Backbone,
  Locale, Toastr,
  EmployeeCollection,
) => Backbone.Marionette.LayoutView.extend({
  template(data) {
    return `<input type="text" class="form-control" placeholder="${data.placeholder}">`;
  },

  ui: {
    input: 'input',
  },

  initialize({
    initialEmployeeModel,
    placeholder,
    allowClear,
  }) {
    this.initialEmployeeModel = initialEmployeeModel || false;
    this.placeholder = placeholder || Locale.translate('search_for_employee');
    this.allowClear = _.isBoolean(allowClear) ? allowClear : false;
  },

  onShow() {
    this.spawnSelect2();
  },

  spawnSelect2() {
    const inputEl = this.ui.input;
    const select2Options = {
      placeholder: this.placeholder,
      containerCssClass: 'container-white form-control',
      dropdownCssClass: 'dropdown-white shop-product-search-simple__dropdown',
      minimumInputLength: 0,
      allowClear: this.allowClear,
      width: '100%',
      query: (query) => {
        this.customerSearch(query);
      },
    };

    // create select2
    inputEl.select2(select2Options)
      .on('select2-selecting', (ev) => {
        this.triggerMethod('employee:selected', ev.choice.model);
      }).on('select2-clearing', () => {
        this.triggerMethod('employee:removed');
      });

    // set data
    if (this.initialEmployeeModel) {
      inputEl.select2('data', this.getSelect2DataFromModel(this.initialEmployeeModel));
    }
  },

  getSelect2DataFromModel(model) {
    return {
      id: model.get('group_member.relation_data_id'),
      text: model.get('managed_subuser.relation_data_profile.name'),
      model,
    };
  },

  customerSearch: _.debounce(function (query) {
    const term = this.term = query.term.toLowerCase();

    EmployeeCollection.load()
      .then(() => {
        const results = EmployeeCollection.chain()
          .filter((model) => {
            const name = model.get('managed_subuser.relation_data_profile.name');
            if (name) {
              return name.toLowerCase().indexOf(term) >= 0;
            }
          })
          .map((model) => this.getSelect2DataFromModel(model))
          .value();

        if (this.term === query.term) {
          query.callback({
            results,
          });
        }
      });
  }, 100),

  serializeData() {
    return {
      placeholder: this.placeholder,
    };
  },

  /**
		 * Public functions
		 */

  open() {
    this.ui.input.select2('open');
  },

}));
