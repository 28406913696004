define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.common/components/onScreenKeyboard',
], (
  $, _, Backbone, WithKeyboardBehaviour,
  Keyboard, OnScreenKeyboard,
) => Backbone.Marionette.ItemView.extend({

  initialize(options) {
    this.placeholder = options.placeholder || '';
    this.autoFocus = options.autoFocus || false;
    this.clearOnConfirm = options.clearOnConfirm || false;
  },

  ui: {
    input: 'input',
  },

  events: {
    'click @ui.input': 'inputClicked',
  },

  inputClicked() {
    Keyboard.stringMode(this.cid);
    this.ui.input.addClass('element-focus');
  },

  template(options) {
    return `<input style="background-color: #FFFFFF;" type="text" class="form-control" placeholder="${options.placeholder || ''}">`;
  },

  serializeData() {
    return {
      placeholder: this.placeholder,
    };
  },

  onShow() {
    Keyboard.on('key:confirm', this.handleConfirmation, this);

    if (this.autoFocus) {
      this.ui.input.focus();
      this.ui.input.click();
      if (OnScreenKeyboard.isRegistered()) {
        // Timeout needed to make sure the input is ready to be registered.
        setTimeout(() => OnScreenKeyboard.registerTargetInput(this.ui.input), 0);
      }
    }
  },

  onDestroy() {
    Keyboard.off('key:confirm', this.handleConfirmation, this);

    Keyboard.setNeutralMode();
  },

  handleConfirmation() {
    if (Keyboard.isViewAndMode(this.cid, Keyboard.MODE_STRING)) {
      this.triggerMethod('value:search', this.ui.input.val());
      if (this.clearOnConfirm) {
        this.ui.input.val('');
      }
    }
  },

}));
