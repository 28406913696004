define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'toastr',
  'modules/shop.cash-register-retail/components/email.js',

  'modules/shop.cash-register-retail/collections/ShopConfigurationAttachedGroup',
  'upx.modules/RelationsModule/collections/FullInfoRelation',

  'modules/admin/components/select2',
], (
  $, _, Backbone,
  Locale, Toastr, Email,
  ShopConfigurationAttachedGroup, FullInfoRelationCollection,
) => Backbone.Marionette.LayoutView.extend({

  defaults: {
    placeholder: Locale.translate('search_for_customer'),
    initialCustomerModel: false,
    relation_data_id: false,
  },

  template(data) {
    return `<input type="text" class="form-control" placeholder="${data.placeholder}">`;
  },

  ui: {
    input: 'input',
  },

  initialize(options) {
    this.options = options = _.extend({}, this.defaults, options);
    this.initialCustomerModel = options.initialCustomerModel;
    this.relation_data_id = options.relation_data_id;
    this.placeholder = options.placeholder;
  },

  onShow() {
    this.spawnSelect2();
  },

  spawnSelect2() {
    const inputEl = this.ui.input;
    const select2Options = {
      placeholder: this.options.placeholder,
      containerCssClass: 'container-white form-control shop-product-search-simple__container',
      dropdownCssClass: 'dropdown-white shop-product-search-simple__dropdown',
      minimumInputLength: 2,
      width: '100%',
      query: (query) => {
        this.customerSearch(query);
      },
    };

    // create select2
    inputEl.select2(select2Options)
      .on('select2-selecting', (ev) => {
        this.triggerMethod('customer:selected', ev.choice.model);
      });

    // set data
    if (this.initialCustomerModel) {
      inputEl.select2('data', this.getSelect2DataFromModel(this.initialCustomerModel));
    }

    // set data
    if (this.relation_data_id) {
      const fullInfoRelationCollection = new FullInfoRelationCollection();
      const fullInfoRelationParameters = {
        start: 0,
        limit: 1,
        filters: [{
          name: 'id__=',
          val: this.relation_data_id,
        }],
      };
      inputEl.select2('data', {
        id: 'null',
        text: `${Locale.translate('loading_customer_details')}...`,
      });
      fullInfoRelationCollection.fetch({ params: fullInfoRelationParameters })
        .then(() => {
          if (fullInfoRelationCollection.length === 1) {
            inputEl.select2('data', this.getSelect2DataFromModel(fullInfoRelationCollection.first()));
          }
        });
    }
  },

  getSelect2DataFromModel(model) {
    const email = Email.cleanEmptyEmail(model.get('contact_set.email'));
    let email_entry = '';

    if (email) {
      email_entry = ` (${email})`;
    }

    return {
      id: model.get('id'),
      text: `${model.get('name')}${email_entry}`,
      model,
    };
  },

  customerSearch: _.debounce(function (query) {
    const itemsPerFetch = 10;
    this.term = query.term;

    ShopConfigurationAttachedGroup.load()
      .then(() => {
        const fullInfoRelationCollection = new FullInfoRelationCollection();
        const fullInfoRelationParameters = {
          start: (query.page - 1) * itemsPerFetch,
          limit: itemsPerFetch,
          filters: this.getCustomerSearchParams(query.term),
        };

        fullInfoRelationCollection.fetch({ params: fullInfoRelationParameters })
          .then((resp) => {
            const moreItems = resp.count >= itemsPerFetch;
            const results = fullInfoRelationCollection.map((model) => this.getSelect2DataFromModel(model));

            // check if the current search term is the same.
            if (this.term === query.term) {
              query.callback({
                more: moreItems,
                results,
              });
            }
          });
      });
  }, 100),

  getCustomerSearchParams(searchTerm) {
    const filters = [{
      name: 'group_id__direct_member_in_list',
      multi_val: [
        ShopConfigurationAttachedGroup.getIdByAlias('Customer'),
      ],
    }, {
      name: 'deleted__=',
      val: false,
    }];

    if (searchTerm !== '') {
      filters.push({
        name: 'search__word',
        val: searchTerm,
      });
    }
    return filters;
  },

  serializeData() {
    return {
      placeholder: this.placeholder,
    };
  },

  /**
		 * Public functions
		 */

  open() {
    this.ui.input.select2('open');
  },

}));
