define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/layouts/app/page',
  'modules/admin/regions/page',
], (
  $, _, Backbone, Template, PageRegion,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  regions: {
    content: '[data-region=content]',
  },

  regionClass: PageRegion,

}));
