define([
  'jquery',
  'backbone',
  'modules/shop.cash-register-retail/templates/login/loginByHash',

  'modules/common/components/locale',
  'backbone.syphon',
  'modules/upx/models/user',
  'modules/upx/collections/users',
  'modules/admin/behaviors/loader',

  'modules/admin/components/select2',
],
(
  $, Backbone, Template,
  Locale, Syphon, User, Users, Loader,
) => Backbone.Marionette.ItemView.extend({
  template: Template,

  className: 'login-by-hash',

  ui: {
    loginBtn: '[js-ui="login"]',
  },

  events: {
    'click @ui.loginBtn': 'swapLogin',
  },

  behaviors: {
    FormLoader: {
      behaviorClass: Loader,
    },
  },

  initialize(options) {
    options = options || {};

    this.inputData = options.data;
  },

  onRender() {
    this.ui.loginBtn.hide();
  },

  swapLogin() {
    this.triggerMethod('layout:swap');
  },

  onShow() {
    try {
      // Destroy existing logged in users
      if (Users.hasActiveUser()) {
        const user = Users.getActiveUser();
        user.destroy();
      }

      const data = JSON.parse(atob(this.inputData));
      Locale.setLocale(data.locale);
      const user = new User(data);
      const def = this.loader.startLoader();
      const self = this;
      $.when(user.login()).then(
        () => {
          self.triggerMethod('setUser', def, user);
        },
        () => {
          self.onError();
          def.reject();
        },
      );
    } catch (err) {
      this.onError();
      console.error(err);
    }
  },

  onError() {
    this.triggerMethod('showError',
      Locale.translate('error'),
      Locale.translate('failed_to_login_automatically'));
    this.ui.loginBtn.show();
  },

}));
