define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/layouts/app/header.hbs',
  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/profile/models/profile',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.common/components/deviceConfig',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, Backbone, Template, Locale, Moment,
  ProfileModel, DefaultShopConfigurationModel, DeviceConfig,
  CashRegisterApi,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  modelEvents: {
    change: 'render',
  },

  className: 'header',

  ui: {
    time: '[data-ui=time]',
    initials: '[data-ui=initials]',
  },

  events: {
    'click [data-action="lock"]': 'lockClicked',
  },

  lockClicked() {
    CashRegisterApi.logAction('HEADER_BUTTON_CLICKED', {
      button: 'lock',
    });
    Backbone.history.navigate('auth/logout', { trigger: true });
  },

  initialize() {
    this.model = new Backbone.Model({
      date: '',
      time: '',
      temperature: '',
      weatherId: 800,
    });
  },

  onShow() {
    this.conditionallyRenderInitialImage();
    const self = this;
    this.updateTime();
    this.timeUpdate = setInterval(() => {
      self.updateTime();
    }, 30000);
    this.updateWeather();
    this.weatherUpdate = setInterval(() => {
      self.updateWeather();
    }, 600000);
  },

  onDestroy() {
    clearInterval(this.timeUpdate);
    clearInterval(this.weatherUpdate);
  },

  updateTime() {
    const moment = new Moment();
    const time = moment.format('HH:mm');
    const date = moment.format('DD MMMM YYYY');
    this.model.set({
      time,
      date,
    });
  },

  onRender() {
    const self = this;
    setTimeout(() => {
      self.conditionallyRenderInitialImage();
    });
  },

  conditionallyRenderInitialImage() {
    if (
      // Check if 'ui.initials' is an jquery object
      this.ui.initials instanceof $
                // And check if there is an element in 'ui.initials'.
                && this.ui.initials.get(0)
    ) {
      const el = this.ui.initials.get(0);
      // THE Colours
      const colours = [
        '#1abc9c',
        '#2ecc71',
        '#db7780',
        '#9b59b6',
        '#34495e',
        '#16a085',
        '#27ae60',
        '#463eb9',
        '#8e44ad',
        '#2c3e50',
        '#f1c40f',
        '#e67e22',
        '#e74c3c',
        '#95a5a6',
        '#f39c12',
        '#d35400',
        '#c0392b',
        '#bdc3c7',
        '#7f8c8d',
      ];

      // Getting the initials
      const name = ProfileModel.getFullProfileName().trim();
      const nameSplit = name.split(' ');
      const first = nameSplit[0].charAt(0).toUpperCase();
      const last = nameSplit[nameSplit.length - 1].charAt(0).toUpperCase();
      const initials = first + last;

      // Deciding which colour to use.
      const charIndex = initials.charCodeAt(0) - 65;
      const colourIndex = charIndex % 19;

      const canvas = el;
      const context = canvas.getContext('2d');
      const canvasWidth = $(canvas).outerWidth();
      const canvasHeight = $(canvas).outerHeight();

      // To encounter ro ratio differences
      if (window.devicePixelRatio) {
        $(canvas).attr('width', canvasWidth * window.devicePixelRatio);
        $(canvas).attr('height', canvasHeight * window.devicePixelRatio);
        context.scale(window.devicePixelRatio, window.devicePixelRatio);
      }

      // Setting the canvas context
      context.fillStyle = colours[colourIndex];
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.font = '30px Arial';
      context.textAlign = 'center';
      context.fillStyle = '#FFF';
      context.fillText(initials, canvasWidth / 2, canvasHeight / 1.5);
    }
  },

  updateWeather() {
    const self = this;
    this.retrieveWeatherData()
      .done((weatherData) => {
        let weatherId = 800;
        const weatherObj = _.first(weatherData.weather);
        if (weatherObj) {
          weatherId = weatherObj.id;
        }

        // Get the current temperature
        const temp_kelvin = weatherData.main.temp;

        // Set the values
        self.model.set({
          temperature: (temp_kelvin - 273.15).toFixed(1),
          weatherId,
        });
      });
  },

  /**
         * ref: https://openweathermap.org/weather-conditions
         * @return {string}
         */
  getWeatherIconCls() {
    const weatherId = this.model.get('weatherId');

    // 2xx
    if (weatherId >= 200 && weatherId < 300) {
      return 'fas fa-thunderstorm';
    } if (weatherId >= 300 && weatherId < 400) {
      return 'fas fa-cloud-drizzle';
    } if (weatherId >= 500 && weatherId < 600) {
      return 'fas fa-cloud-rain';
    } if (weatherId >= 600 && weatherId < 700) {
      return 'fas fa-cloud-snow';
    } if (weatherId >= 700 && weatherId < 800) {
      return 'fas fa-fog';
    } if (weatherId === 800) {
      return 'fas fa-sun';
    } if (weatherId >= 801) {
      return 'fas fa-cloud';
    }

    // Unknown

    return '';
  },

  getUserImage() {
    if (ProfileModel.has('relation_data_profile.image_url')) {
      return ProfileModel.get('relation_data_profile.image_url');
    }
  },

  serializeData() {
    let title;
    const relation = DefaultShopConfigurationModel.getRelation();
    if (relation) {
      title = relation.get('name');
    }

    return {
      title,
      temperature: this.model.get('temperature'),
      rains: this.model.get('rains'),
      weatherIconCls: this.getWeatherIconCls(),
      time: this.model.get('time'),
      date: this.model.get('date'),
      user_image: this.getUserImage(),
      posName: DeviceConfig.getDeviceName(),
    };
  },

  retrieveWeatherData() {
    const APP_ID = '57ca597aa0d68b7fa7bd15139f94312e';
    const locationConfig = DeviceConfig.getConfig(DeviceConfig.PATH_Location);
    let location = locationConfig ? locationConfig.get('extra.city') : null;
    location = location || 'Enschede';

    const def = new $.Deferred();
    const url = `${'https://api.openweathermap.org/data/2.5/weather?'
                + 'q='}${encodeURIComponent(location)
    }&appid=${encodeURIComponent(APP_ID)}`;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onreadystatechange = function () {
      let status;
      let data;

      // https://xhr.spec.whatwg.org/#dom-xmlhttprequest-readystate
      if (xhr.readyState == 4) { // `DONE`
        status = xhr.status;
        if (status == 200) {
          data = JSON.parse(xhr.responseText);
          def.resolve(data);
        } else {
          def.reject();
        }
      }
    };
    xhr.send();

    return def;
  },
}));
