define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/inputs/selectInput',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'select',

  className: 'form-control',

  collection: new Backbone.Collection(),

  defaults: {
    targetPath: null,
		    emptyLabel: false,
		    idPath: 'id',
    isValueInt: true,
    labelPath: 'label',
    presetFirstIfOneOption: false,
  },

  initialize(options) {
		    this.options = _.extend({}, this.defaults, options);

    this.emptyLabel = this.options.emptyLabel;
    this.presetFirstIfOneOption = this.options.presetFirstIfOneOption;

    this.idPath = this.options.idPath;
    this.labelPath = this.options.labelPath;

    this.targetPath = this.options.targetPath;
    this.isValueInt = !!this.options.isValueInt;
  },

  getOptions() {
		    if ('map' in this.collection) {
      return this.collection.map((optionModel) => ({
        id: optionModel.get(this.idPath),
        label: optionModel.get(this.labelPath),
      }));
    }

		    return [];
  },

  serializeData() {
		    return {
      emptyLabel: this.emptyLabel,
      options: this.getOptions(),
    };
  },

  events: {
		    change: 'inputChanged',
  },

  inputChanged() {
		    let { value } = this.el;
		    if (this.isValueInt) {
      // try to parse the value to a interger. FindWhere also checks if the type is the same.
      if (!_.isNaN(parseInt(value))) {
        value = parseInt(value);
      } else {
        console.warn('Parsing value to int failed for selectInput', value);
      }
    }

		    const isOption = !!this.collection.findWhere({ [this.idPath]: value });

		    if (isOption) {
		        this.model.set(this.targetPath, value);
    } else {
      this.model.unset(this.targetPath);
    }
  },

  onShow() {
		    this.collection.on('all', this.bounceRender, this);
		    this.model.on(`change:${this.targetPath}`, this.updateInput, this);
  },

  preselect() {
    if (
      !this.model.get(this.targetPath) // target not set
				&& this.presetFirstIfOneOption // option enabled
				&& this.collection.length === 1 // has only one option
    ) {
      const firstOption = this.collection.first();
      if (firstOption) {
        this.$el.val(firstOption.get(this.idPath));
        this.inputChanged();
      }
    }
  },

  onDestroy() {
		    this.collection.off('all', this.bounceRender, this);
    this.model.off(`change:${this.targetPath}`, this.updateInput, this);
  },

  updateInput() {
    const currentValue = this.el.value;
    const modelValue = this.model.get(this.targetPath);
    const isOption = !!this.collection.findWhere({ [this.idPath]: modelValue });

    if (currentValue !== modelValue && isOption) {
      this.el.value = modelValue;
    }
  },

  bounceRender() {
    this.render();
  },

  onRender() {
    this.updateInput();

    this.preselect();
  },

}));
