define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/layouts/app/fullscreenLoader.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className() {
    const cls = ['progress-wrapper'];
    if (this.options && this.options.extraClassName) {
      cls.push(this.options.extraClassName);
    }
    return cls.join(' ');
  },

  regions: {
    'status-view': '[data-region=status-view]',
    'cashier-display': '[data-region=cashier-display]',
  },

  initialize(options) {
    options = options || {};

    const data = {
      message: options.message,
      title: options.title,
    };
    if (!('showLoader' in options)) {

    }
    if (!('loaderType' in options)) {
      options.loaderType = 'spinner';
      // other options: progress
    }
    if (options.loaderType === 'spinner') {
      data.showSpinner = true;
    } else if (options.loaderType === 'progress') {
      data.showProgress = true;
    }
    this.statusView = options.statusView;
    this.cashierDisplay = options.cashierDisplay;
    this.renderData = data;
  },

  onRender() {
    if (this.statusView) {
      this.getRegion('status-view').show(this.statusView);
    }
    if (this.cashierDisplay) {
      this.getRegion('cashier-display').show(this.cashierDisplay);
    }
  },

  serializeData() {
    return this.renderData;
  },
}));
